import React from 'react'
import PropTypes from 'prop-types'

class LockerRoom extends React.Component	
{
	constructor(props)
	{
		super(props)
		this.state = { }
	}

	translatePlace(type)
	{
		const map = {
			'sundthus': 'SundtHus',
			'jysk':	'Jysk',
			'jjd':	'JJD',
			'ojm':	'O. Jacobsen Maskinfabrik',
			'bms':	'BM Silo',
			'vb':	'Vestjysk Bank',
			'dom': 'Dameomklædning i kælderen',
			'hom': 'Herreomklædning i kælderen',
		}
		return map[type]
	}
	render()
	{
		const entry = this.props.json

		return (
			<div className="name-part">{entry.eventName}</div>
		)
	}
}

LockerRoom.propTypes = {
	json: PropTypes.object,
	first: PropTypes.bool,
}

export default LockerRoom
