import React from 'react'
import Form from 'react-bootstrap/esm/Form'
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/esm/Container'
import Button from 'react-bootstrap/esm/Button'
import Alert from 'react-bootstrap/esm/Alert'
import {get_server_url} from '../variables'
class Login extends React.Component	
{
	constructor(props)
	{
		super(props)
		this.state= {
			password: '',
			error: false,
		}
	}
	handleInputChange(e)
	{
		let value = e.target.value
		if (e.target.type === 'checkbox')
		{
			value = e.target.checked
		}
		this.setState({ [e.target.name]: value })
	}
	login()
	{
		fetch(get_server_url()+'/login.php', {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'unsafe-url',
			body: JSON.stringify({password:this.state.password}),// body data type must match "Content-Type" header
		})
			.then(response => response.json())
			.then(result =>
			{
				if (result === 'failed')
				{
					this.setState({error: true})
				}
				else
				{
					window.location.replace('/')
				}
				
			})
	}
	render()
	{
		return (
			<Container className="h-100">
				<Row className="h-100 justify-content-center align-items-center mt-5">
					<Col md={6}>
						<Alert variant="danger" className={this.state.error ? '': 'd-none'}>
							Forkert login
						</Alert>
						<h1>Login</h1>
						<Form.Group controlId="Password">
							<FloatingLabel controlId="floatingSelect" label="Adgangskode">
								<Form.Control
									type="password"
									name="password"
									placeholder="Adgangskode"
									value={this.state.password}
									onChange={(e) => this.handleInputChange(e)}
								/>
							</FloatingLabel>
						</Form.Group>
						<Button variant="primary" className="mt-3 p-3" onClick={() => this.login()}>
							Log ind
						</Button>
					</Col>
				</Row>
			</Container>
			

		)
	}
}


export default Login
