import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import Form from 'react-bootstrap/esm/Form'
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel'
import Button from 'react-bootstrap/esm/Button'
import Alert from 'react-bootstrap/esm/Alert'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import PropTypes from 'prop-types'
import {get_server_url} from '../variables'

class CreateUpdate extends React.Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			id: this.props.id,
			eventName: '',
			subEventName: '',
			eventPlace: '',
			recurring: false,
			type:'',
			activityFrom:new Date(),
			weekday: '',
			timeFrom: '',
			timeToo: '',
			deleted: false,
			fieldsWithErrors: [],
			errors: [],
			saved: false,
			message: '',
			timeOut: '',
			fullsize: false,
		}
	}
	handleInputChange(e)
	{
		let value = e.target.value
		if (e.target.type === 'checkbox')
		{
			value = e.target.checked
		}
		
		this.setState({ [e.target.name]: value })
	}
	handleDateChange(date, name)
	{
		this.setState({ [name]: date })
	}
	handleWeekDayChange(e)
	{
		this.setState({weekday: e.target.value})
	}
	handlePlaceChange(e)
	{
		this.setState({eventPlace: e.target.value})
	}
	handleSubmit(e)
	{
		e.preventDefault()
		this.save()
	}
	delete(e)
	{
		e.preventDefault()
		//Soft delete
		this.setState({deleted:true})
		this.save(true)
	}
	validate()
	{
		const failed_input = []
		const errorMsg = []
		if (this.state.eventName === '')
		{
			failed_input.push('eventName')
			errorMsg.push('Navn må ikke være tom')
		}

		if (this.state.eventPlace === '' && this.state.type == 3) // 3 is omklædning
		{
			failed_input.push('eventPlace')
			errorMsg.push('Sted må ikke være tom når du har valgt omklædning')
		} 

		if (this.state.type === '')
		{
			failed_input.push('type')
			errorMsg.push('Type må ikke være tom')
		} 

		if (this.state.timeFrom === '')
		{
			failed_input.push('timeFrom')	
			errorMsg.push('Tid-fra må ikke være tom')
		}
		else if (!this.state.timeFrom.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)) 
		{
			failed_input.push('timeFrom')
			errorMsg.push('Forkert tidsformat, skal skrive som TT:MM f.eks. 14:30')
		} 

		if (this.state.timeToo === '')
		{
			failed_input.push('timeToo')
			errorMsg.push('Tid-til må ikke være tom')

		}
		else if (!this.state.timeToo.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)) 
		{
			failed_input.push('timeToo')
			errorMsg.push('Forkert tidsformat, skal skrive som TT:MM f.eks. 14:30')
		}

		if (this.state.activityFrom === '' && !this.state.recurring)
		{
			failed_input.push('activityFrom')
			errorMsg.push('Fra dato må ikke være tom')
		}

		if (this.state.weekday === '' && this.state.reucrring)
		{
			failed_input.push('weekday')
			errorMsg.push('Ugedag må ikke være tom')
		}


		this.setState({fieldsWithErrors: failed_input})
		this.setState({errors: errorMsg})
		
		if (failed_input.length !== 0)
		{
			return false
		}

		return true
	}
	save(omit_validatation = false)
	{
		if (omit_validatation === false)
		{
			if (this.validate() === false)
			{
				return false
			}
		}
		this.setState({
			timeFrom:  this.state.timeFrom.padStart(5, 0),
			timeToo: this.state.timeToo.padStart(5, 0),
		}, () =>
		{
		
			fetch(get_server_url() + '/', {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				credentials: 'include',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				redirect: 'follow', // manual, *follow, error
				referrerPolicy: 'unsafe-url',
				body: JSON.stringify(this.state),// body data type must match "Content-Type" header
			})
				.then(response => response.json())
				.then(result =>
				{
					this.setState({id: result.id})
					this.setState({saved: true})
					this.props.getRecurringEvents()
					setTimeout(() => this.clearAlert(), 1000)
				})
		})
	}
	clearAlert()
	{
		this.setState({saved: false})
	}
	componentDidMount()
	{
		/*fetch(get_server_url()+'/?message=true', {
			method: 'GET', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			redirect: 'follow', // manual, *follow, errorv
			referrerPolicy: 'unsafe-url',
		})
			.then(response => response.json())
			.then(result =>
			{
				this.setState({message:result})
			})*/

		let id = this.state.id
		if (id === '')
		{
			return
		}

		this.setState({id: id})
		fetch(get_server_url()+'/?cmd=get&id='+id, {
			method: 'GET', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			redirect: 'follow', // manual, *follow, errorv
			referrerPolicy: 'unsafe-url',
		})
			.then(response => response.json())
			.then(result =>
			{
				for (const key in result) 
				{
					if (key === 'activityFrom')
					{
						result[key] = new Date(result[key])
					}
				}

				this.setState(result)
			})
	}
	handleMessage(e)
	{
		this.setState({message:e.target.value})
		clearTimeout(this.state.timeOut)
		let wat = setTimeout(function()
		{ 
			fetch(get_server_url()+'/save.php?message='+encodeURIComponent(e.target.value), {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				credentials: 'include',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				redirect: 'follow', // manual, *follow, error
				referrerPolicy: 'unsafe-url',
				body: e.target.value,// body data type must match "Content-Type" header
			})
				.then(response => response.json())
				.then(result =>
				{
					console.log(result)
				})
		}, 300)

		this.setState({timeOut: wat})

	}
	setNext(day)
	{
		var now = new Date()
		now.setDate(now.getDate() + (day+(7-now.getDay())) % 7)
		this.setState({activityFrom: now})
	}
	render()
	{
		const errorMessagesArray = this.state.errors.map(string => <React.Fragment key={string}>{string}
			<br />
		</React.Fragment>)

		return (
			<Col lg={6}>
				<h1><span className={this.state.deleted ? 'text-danger' : 'd-none'}>Slettet!</span></h1>
				<Form>
					<Alert variant="danger" className={this.state.errors.length === 0 ? 'd-none' : ''}>
						{errorMessagesArray}
					</Alert>
					<input type="hidden" value={this.state.id} />
					<a href="/" className="btn btn-primary mb-3">Ny</a>

					<FloatingLabel controlId="floatingSelect" className="mb-3" label="Type">
						<Form.Select
							aria-label="floatingSelect"
							name="type"
							value={this.state.type}
							onChange={(e) => this.handleInputChange(e)}
							isInvalid={this.state.fieldsWithErrors.includes('type')}
						>
							<option>Vælg</option>
							<option value="1">Hallen</option>
							<option value="4">Kultursalen</option>
							{/* <option value="2">Møde</option> */}
							<option value="3">Omklædning</option>
						</Form.Select>
					</FloatingLabel>

					<div className={(this.state.type !== '3' ? 'd-none' : '') + ' mb-3'}>
						<Form.Check
							onChange={(e) => this.handlePlaceChange(e)}
							inline
							name="eventPlace"
							label="SundtHus"
							value="sundthus"
							type="radio"
							id="eventPlace-Selector-sundthus"
							checked={this.state.eventPlace === 'sundthus' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('eventPlace')}
						/>
						<Form.Check
							onChange={(e) => this.handlePlaceChange(e)}
							inline
							name="eventPlace"
							label="Jysk"
							value="jysk"
							type="radio"
							id="eventPlace-Selector-jysk"
							checked={this.state.eventPlace === 'jysk' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('eventPlace')}
						/>
						<Form.Check
							onChange={(e) => this.handlePlaceChange(e)}
							inline
							name="eventPlace"
							label="JJD"
							value="jjd"
							type="radio"
							id="eventPlace-Selector-jjd"
							checked={this.state.eventPlace === 'jjd' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('eventPlace')}
						/>
						<Form.Check
							onChange={(e) => this.handlePlaceChange(e)}
							inline
							name="eventPlace"
							label="O. Jacobsens Maskinfabrik"
							value="ojm"
							type="radio"
							id="eventPlace-Selector-ojm"
							checked={this.state.eventPlace === 'ojm' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('eventPlace')}
						/>
						<Form.Check
							onChange={(e) => this.handlePlaceChange(e)}
							inline
							name="eventPlace"
							label="BM Silo"
							value="bms"
							type="radio"
							id="eventPlace-Selector-bms"
							checked={this.state.eventPlace === 'bms' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('eventPlace')}
						/>
						<Form.Check
							onChange={(e) => this.handlePlaceChange(e)}
							inline
							name="eventPlace"
							label="Vestjyskbank A/S"
							value="vb"
							type="radio"
							id="eventPlace-Selector-vb"
							checked={this.state.eventPlace === 'vb' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('eventPlace')}
						/>
						<Form.Check
							onChange={(e) => this.handlePlaceChange(e)}
							inline
							name="eventPlace"
							label="Dameomklædning i kælderen"
							value="dom"
							type="radio"
							id="eventPlace-Selector-dom"
							checked={this.state.eventPlace === 'dom' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('eventPlace')}
						/>
						<Form.Check
							onChange={(e) => this.handlePlaceChange(e)}
							inline
							name="eventPlace"
							label="Herreomklædning i kælderen"
							value="hom"
							type="radio"
							id="eventPlace-Selector-hom"
							checked={this.state.eventPlace === 'hom' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('eventPlace')}
						/>
					</div>

					<Form.Group className="mb-3" controlId="formBasicEmail">
						<FloatingLabel controlId="floatingSelect" label="Titel">
							<Form.Control
								type="text"
								name="eventName"
								placeholder="Titel"
								onChange={(e) => this.handleInputChange(e)}
								value={this.state.eventName}
								isInvalid={this.state.fieldsWithErrors.includes('eventName')}
							/>
						</FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="formBasicEmail">
						<FloatingLabel controlId="floatingSelect" label="Undertitel">
							<Form.Control
								type="text"
								name="subEventName"
								placeholder="Undertitel"
								onChange={(e) => this.handleInputChange(e)}
								value={this.state.subEventName}
								isInvalid={this.state.fieldsWithErrors.includes('subEventName')}
							/>
						</FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="formBasicCheckbox">
						<Form.Check
							type="checkbox"
							label="Tilbagevendende"
							name="recurring"
							checked={this.state.recurring}
							onChange={(e) => this.handleInputChange(e)}
						/>
	
					</Form.Group>

					{/* <Form.Group className="mb-3" controlId="fullsize">
						<Form.Check
							type="checkbox"
							label="Fyld det hele"
							name="fullsize"
							checked={this.state.fullsize}
							onChange={(e) => this.handleInputChange(e)}
						/>
					</Form.Group> */}

					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="timeFrom">
								<FloatingLabel controlId="timeFrom" label="Tidspunkt fra">
									<Form.Control
										type="text"
										name="timeFrom"
										placeholder="Tidspunkt fra"
										required
										value={this.state.timeFrom}
										onChange={(e) => this.handleInputChange(e)}
										isInvalid={this.state.fieldsWithErrors.includes('timeFrom')}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
						
						<Col>
							<Form.Group className="mb-3" controlId="timeToo">
								<FloatingLabel controlId="timeToo" label="Tidspunkt til">
									<Form.Control
										type="text"
										name="timeToo"
										placeholder="Tidspunkt til"
										required
										value={this.state.timeToo}
										onChange={(e) => this.handleInputChange(e)}
										isInvalid={this.state.fieldsWithErrors.includes('timeToo')}

									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
					</Row>
					
					<Row>
						<Col>
							<DatePicker
								onChange={(date) => this.handleDateChange(date, 'activityFrom')}
								name="activityFrom"
								selected={this.state.activityFrom}
								dateFormat="dd/MM/yyyy"
								className={(this.state.fieldsWithErrors.includes('activityFrom') ? 'is-invalid' : '') +(this.state.recurring  ? ' d-none ' : '') + 'mb-3 w-100 form-control'	}
							/>
						</Col>
						<Col>
							<Button className="me-1" onClick={() => this.setNext(6)}>Næste Lørdag</Button>
							<Button onClick={() => this.setNext(7)}>Næste Søndag</Button>
						</Col>
					</Row>

					<div className={(this.state.recurring  ? '' : 'd-none')}>
						<Form.Check
							onChange={(e) => this.handleWeekDayChange(e)}
							inline
							name="weekday"
							label="Mandag"
							value="monday"
							type="radio"
							id="Weekday-Selector-Mondag"
							checked={this.state.weekday === 'monday' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('weekday')}
						/>
						<Form.Check
							onChange={(e) => this.handleWeekDayChange(e)}
							inline
							name="weekday"
							value="tuesday"
							label="Tirsdag"
							type="radio"
							id="Weekday-Selector-Tuesday"
							checked={this.state.weekday === 'tuesday' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('weekday')}

						/>
						<Form.Check
							onChange={(e) => this.handleWeekDayChange(e)}
							inline
							name="weekday"
							value="wednesday"
							label="Onsdag"
							type="radio"
							id="Weekday-Selector-Wednesday"
							checked={this.state.weekday === 'wednesday' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('weekday')}

						/>
						<Form.Check
							onChange={(e) => this.handleWeekDayChange(e)}
							inline
							value="thursday"
							name="weekday"
							label="Torsdag"
							type="radio"
							id="Weekday-Selector-Thursday"
							checked={this.state.weekday === 'thursday' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('weekday')}

						/>
						<Form.Check
							onChange={(e) => this.handleWeekDayChange(e)}
							inline
							value="friday"
							name="weekday"
							label="Fredag"
							type="radio"
							id="Weekday-Selector-Friday"
							checked={this.state.weekday === 'friday' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('weekday')}

						/>
						<Form.Check
							value="saturday"
							onChange={(e) => this.handleWeekDayChange(e)}
							inline
							name="weekday"
							label="Lørdag"
							type="radio"
							id="Weekday-Selector-Saturday"
							checked={this.state.weekday === 'saturday' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('weekday')}

						/>
						<Form.Check
							value="sunday"
							onChange={(e) => this.handleWeekDayChange(e)}
							inline
							name="weekday"
							label="Søndag"
							type="radio"
							id="Weekday-Selector-Sunday"
							checked={this.state.weekday === 'sunday' ? true : false}
							isInvalid={this.state.fieldsWithErrors.includes('weekday')}

						/>
					</div>
					<Row>
						<Col>
							<Row>
								<Col>
									<Button className="col-5 m-5" variant="primary" type="submit" onClick={(e) => this.handleSubmit(e)}>
										Gem
									</Button>
								</Col>
								<Col>
									<Button className={(this.state.id === '' || this.state.id === 'undefined' ? 'd-none' : '') + ' col-5 m-5'} variant="danger" type="submit" 
										onClick={(e) => this.delete(e)}>
										Slet
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<Alert variant="success" className={(this.state.saved ? '' : 'd-none') + ' mt-3'}>
								Gemt!
							</Alert>
						</Col>
					</Row>
				</Form>
				{/* <Row>
					<Col>
						<Form.Group className="mb-3" controlId="">
							<FloatingLabel label="Besked">
								<Form.Control
									type="text"
									name="message"
									placeholder="Besked"
									onChange={(e) => this.handleMessage(e)}
									value={this.state.message}
								/>
							</FloatingLabel>
						</Form.Group>
					</Col>
				</Row> */}
			</Col>
		)
	}
}

CreateUpdate.propTypes = {
	getRecurringEvents: PropTypes.func,
	id: PropTypes.string,
}

export default CreateUpdate
