import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Card from 'react-bootstrap/esm/Card'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import {get_server_url} from '../variables'
import ActivityEntry from './ActivityEntry'
import LockerRoom from './LockerRoom'
import Sponsor from './Sponsor'
import {CardBody} from 'react-bootstrap'

class Activity extends React.Component	
{
	constructor(props)
	{
		super(props)
		this.state = {
			anyLockerRoom: false,
			entriesEvent: '',
			entriesMeating: '',
			entriesLockerRoom: {
				'sundthus': [],
				'jysk':	[],
				'jjd':	[],
				'ojm':	[],
				'bms':	[],
				'vb':	[],
				'dom': [],
				'hom': [],
			},
			entriesKultur: '',
		}
	}
	translatePlace(type)
	{
		const map = {
			'sundthus': 'SundtHus',
			'jysk':	'Jysk',
			'jjd':	'JJD',
			'ojm':	'O. Jacobsen Maskinfabrik',
			'bms':	'BM Silo',
			'vb':	'Vestjysk Bank',
			'dom': 'Dameomklædning i kælderen',
			'hom': 'Herreomklædning i kælderen',
		}
		return map[type]
	}
	getActiveActivities()
	{
		var event = []
		var meating = []
		var lockerRoom = {
			'sundthus': [],
			'jysk':	[],
			'jjd':	[],
			'ojm':	[],
			'bms':	[],
			'vb':	[],
			'dom': [],
			'hom': [],
		}
		var kultur = []
		fetch(get_server_url()+'/?cmd=get&active=true', {
			method: 'GET', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			redirect: 'follow', // manual, *follow, error
		})
			.then(response => response.json())
			.then(result =>
			{
				let any_locker_room = false

				for (let i = 0; i < result.length; i++)
				{	
					const entry = result[i]
					let first_event = true
					let first_locker_room = true
					let first_culture = true
					switch (entry.type)
					{
						case 1: case '1': case 2: case '2':
							event.push(<ActivityEntry json={entry} first={first_event} />)
							first_event = false
							break

						case 4: case '4':
							kultur.push(<ActivityEntry json={entry} first={first_culture} />)
							first_culture = false
							break

						case 3: case '3':
							lockerRoom[entry['eventPlace']].push(<LockerRoom json={entry}  first={first_locker_room} />)
							first_locker_room = false
							any_locker_room = true
							break
						default:
							break
					}
				}

				this.setState(
					{
						anyLockerRoom: any_locker_room,
						entriesEvent: event,
						entriesMeating: meating,
						entriesLockerRoom: lockerRoom,
						entriesKultur: kultur,
					},
				)
			})
	}

	componentDidMount()
	{
		window.setInterval(() => this.getActiveActivities(), 30000)
		this.getActiveActivities()
		document.querySelector('body').style.overflow = 'hidden'

		const element = document.querySelector('.omklaedning-entries')
		if (element)
		{
			// Check if the content overflows

			this.scrollInterval = setInterval(() =>
			{
				if (element.scrollHeight > element.clientHeight) 
				{
					if (element.scrollTop <= 0) 
					{
						this.direction = 1
					}
					else if (element.scrollHeight - element.clientHeight === element.scrollTop) 
					{
						this.direction = -1
					}
				}

				element.scrollTop += this.direction * 5
			}, 100)

		}
	}

	render()
	{
		return (
			<div>
				<Container fluid className="entries">
					<Row>
						<Col className="hallen" xs={(this.state.anyLockerRoom ? 7 : 12)}>
							<Card style={{'marginBottom': '10px'}} >
								<CardBody style={{textAlign: 'center'}}>
									<h1 style={{fontSize:'70px'}}>Hallen</h1>
								</CardBody>
							</Card>
							{this.state.entriesEvent}

							<Card
								style={{'marginBottom': '10px'}}
								className={(this.state.entriesKultur.length === 0 ? 'd-none' : '')}>
								<CardBody style={{textAlign: 'center'}}>
									<h1 style={{fontSize:'70px'}}>Kultursalen</h1>
								</CardBody>
							</Card>
							{this.state.entriesKultur}

						</Col>

						<Col id={'lockerRooms'} xs={(this.state.anyLockerRoom ? 5 : 0)} className={(this.state.anyLockerRoom ? '' : 'd-none')}>
							<Row>
								<Col className="omklaedning" xs={12}>
									<Card style={{marginBottom: '10px'}}>
										<CardBody style={{textAlign: 'center'}}>
											<h1 style={{fontSize:'70px'}}>Omklædning</h1>
										</CardBody>
									</Card>
									<div className="omklaedning-entries">
										{Object.keys(this.state.entriesLockerRoom).map((key) => (
											<Card className={(this.state.entriesLockerRoom[key].length === 0 ? 'd-none' : '')} key={key}>
												<CardHeader className={'pb-0'}>
													<h2 className={'mb-0'}>{this.translatePlace(key)}</h2>
												</CardHeader>
												<CardBody>
													{this.state.entriesLockerRoom[key]}
												</CardBody>
											</Card>
										))}
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
				<Sponsor />
			</div>
		)
	}
}
/**
 * 
 * 
 * 'sundthus': 'SundtHus',
			'jysk':	'Jysk',
			'jjd':	'JJD',
			'ojm':	'O. Jacobsen Maskinfabrik',
			'bms':	'BM Silo',
			'vb':	'Vestjysk Bank',
			'dom': 'Dameomklædning i kælderen',
			'hom': 'Herreomklædning i kælderen',
 */

export default Activity
