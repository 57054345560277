import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Table from 'react-bootstrap/esm/Table'
import CreateUpdate from './CreateUpdate'
import Entry from './Entry'
import {get_server_url} from '../variables'
class Admin extends React.Component	
{
	constructor(props)
	{
		super(props)
		let id = window.location.search.substr(1).replace('id=', '')
		this.state = {
			entries: '',
			entriesTimeRestricted: '',
			entriesOld: '',
			id: id,
		}
		
	}
	translateType(type)
	{
		const map = {
			1: 'Hallen',
			/*2: 'Møde',*/
			3: 'Omklædning',
			4: 'Kultursalen',
		}
		return map[type]
	}
	getRecurringEvents()
	{
		var html = []
		fetch(get_server_url()+'/?cmd=get&recurring=true', {
			method: 'GET', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'unsafe-url',
		})
			.then(response => response.json())
			.then(result =>
			{
				let last_type = null
				for (let i = 0; i < result.length; i++)
				{	
					const entry = result[i]

					if (last_type != entry.type)
					{
						html.push(<tr><td colSpan="100" className="text-center"><h4>{this.translateType(entry.type)}</h4></td></tr>)
					}
					last_type = entry.type
					html.push(<Entry json={entry} id={this.state.id} />)
				}
				
				this.setState({entries: html})
			})

		const wat = []
		fetch(get_server_url()+'/?cmd=get&recurring=false', {
			method: 'GET', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'unsafe-url',
		})
			.then(response => response.json())
			.then(result =>
			{
				let last_type = null
				for (let i = 0; i < result.length; i++)
				{	
					const entry = result[i]
					if (last_type != entry.type)
					{
						wat.push(<tr><td colSpan="100" className="text-center"><h4>{this.translateType(entry.type)}</h4></td></tr>)
					}
					last_type = entry.type
					wat.push(<Entry json={entry} id={this.state.id}  />)
				}
				
				this.setState({entriesTimeRestricted: wat})
			})
	}
	componentDidMount()
	{
		this.getRecurringEvents()
	}
	render()
	{
		return (
			<Container fluid className="admin">
				<Row>
					<CreateUpdate id={this.state.id} getRecurringEvents={() => this.getRecurringEvents()} />
					<Col lg={6}>
						<h3>Tidsbegrænset</h3>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>Navn</th>
									<th>Sted</th>
									<th>Tid fra</th>
									<th>Tid til</th>
									<th>Dato</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{this.state.entriesTimeRestricted}
							</tbody>
						</Table>
						<h3>Tilbagevendende</h3>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>Navn</th>
									<th>Sted</th>
									<th>Tid fra</th>
									<th>Tid til</th>
									<th>Ugedag</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{this.state.entries}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		)
	}
}

export default Admin
