//import logo from './logo.svg'
import './App.css'
import './core.css'
import './theme-default.css' //Sneat
import React from 'react'
import Activity from './components/Activity'
import Admin from './components/Admin'
import Login from './components/Login'

class App extends React.Component	
{
	componentDidMount()
	{
		console.log(document.cookie)
	}
	render()
	{
		if (window.location.pathname === '/login')
		{
			return (
				<Login />
			)
		}
		else if (window.location.pathname === '/dash')
		{
			return (
				<Activity />
		
			)
		}
	
		return (
			<Admin />
		)
	}
}

export default App
