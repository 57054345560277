import React from 'react'
import PropTypes from 'prop-types'


class Entry extends React.Component	
{
	constructor(props)
	{
		super(props)
		this.state = { }
	}
	translateWeekday(weekday)
	{
		const map = {
			monday: 'Mandag',
			tuesday: 'Tirsdag',
			wednesday: 'Onsdag',
			thursday: 'Torsdag',
			friday: 'Fredag',
			saturday: 'Lørdag',
			sunday: 'Søndag',
		}

		return map[weekday]
	}
	translatePlace(type)
	{
		const map = {
			'sundthus': 'SundtHus',
			'jysk':	'Jysk',
			'jjd':	'JJD',
			'ojm':	'O. Jacobsen Maskinfabrik',
			'bms':	'BM Silo',
			'vb':	'Vestjysk Bank',
			'dom': 'Dameomklædning i kælderen',
			'hom': 'Herreomklædning i kælderen',
		}
		return map[type]
	}
	render()
	{
		const entry = this.props.json
		return (
			<tr className={entry.id == this.props.id ? 'table-primary' : ''}>
				<td>{entry.eventName}</td>
				<td>{entry.eventPlace == '' ? '-' : this.translatePlace(entry.eventPlace)}</td>
				<td>{entry.timeFrom}</td>
				<td>{entry.timeToo}</td>
				<td className={entry.recurring ? 'd-none' : ''}>{new Date(entry.activityFrom).toLocaleDateString('da-DK')}</td>
				<td className={entry.recurring ? '' : 'd-none'}>{this.translateWeekday(entry.weekday)}</td>
				<td><a className="btn btn-primary" href={'?id='+entry.id}>Ret</a></td>
			</tr>
		)
	}
}

Entry.propTypes = {
	json: PropTypes.object,
	id: PropTypes.string,
}

export default Entry
